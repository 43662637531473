import { useState } from "react";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faClipboardList } from "@fortawesome/free-solid-svg-icons";

import { useUser } from "../../UserContext";
import { logout } from "../../../services/auth";

import Icon from "../../Icon";
import CategoriesListCHK from "../../CategoriesListCHK";
import OutletList from "../../OutletList";
import OriginList from "../../OriginList";
import SearchForm from "../../SearchForm";
import UserInfo from "../../UserInfo";
import CTA from "../../CTA";


import {
  Hamburger,
  Scroll,
  MobileNav,
  CategoriesHolder,
  Container,
  ContainerRelease,
  ContainerOrigin,
  ContainerOutlet,
  MenuItem,
  Logout,
  Close
} from "./styles";
import NoImageList from "../../NoImageList";
import { LogoMenu, Logos } from "../MobileNavPN/styles";

export default ({loadScreen}) => {
  const [openNavbar, setOpenNavbar] = useState(false);
  const { user, setUser } = useUser();
  async function generateLoading(isLoading) {
    return loadScreen(isLoading);
  }

  return (
    <>
      <Hamburger onClick={() => setOpenNavbar(!openNavbar)}>
        <Icon color="#000" size="30">
          <FontAwesomeIcon icon={openNavbar ? faTimes : faBars} />
        </Icon>
      </Hamburger>

      {openNavbar && (
        <>
          <MobileNav>
            <UserInfo />
            <Scroll>
              <div style={{ margin: "10px 0" }}>
                {user ? (
                  <Logout onClick={() => logout(setUser)}>Sair</Logout>
                ) : (
                  <CTA href="/cadastrar">Cadastre-se</CTA>
                )}
              </div>
                  <SearchForm />
                {(user?.company_user == true) && 
                <>
                 <div class="parent">Filtros Diversos</div>
                <CategoriesHolder>
                <ContainerOrigin>
                  <NoImageList loadScreen={generateLoading}/>
                </ContainerOrigin>
                </CategoriesHolder>
                </>
                }
                {(user?.profile == "costumer" || user == null)  ? (
            ""
          ) : (
            <>
              <div class="parent">Origem</div>
                <CategoriesHolder>
                <ContainerOrigin>
                  <OriginList loadScreen={generateLoading}/>
                </ContainerOrigin>
                </CategoriesHolder>

              <div id="container-outlet">
              <div class="parent">Outlet</div>
                <CategoriesHolder>
                <ContainerOutlet>
                  <OutletList loadScreen={generateLoading}/>
                </ContainerOutlet>
                </CategoriesHolder>
              </div>
                  
              <div id="container">
              <div class="parent">Categorias</div>
                <CategoriesHolder>
                <Container>
                  <CategoriesListCHK loadScreen={generateLoading}/>
                </Container>
                </CategoriesHolder>
              </div>
              </>
          )}
              <MenuItem
                href="https://blog.goodsbr.com.br"
                rel="noopener noreferrer"
                target="_blank"
              >
                Blog
              </MenuItem>
              
              <MenuItem
                href="https://empreendimentos.goodsbr.com.br/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Empreendimentos
              </MenuItem>
              <MenuItem
                href="https://portal.goodsbr.com.br"
                rel="noopener noreferrer"
                target="_blank"
              >
                Portal
              </MenuItem>
              <MenuItem
                href="https://m.goodsbr.com.br/club-goods-br"
                rel="noopener noreferrer"
                target="_blank"
              >
                VIP
              </MenuItem>
              {(user?.profile == "costumer" || user == null)  ? (
              <Link href="/busca?q=" passHref>
                <MenuItem>Produtos</MenuItem>
              </Link>
                ) : (
              <Link href="/outlet" passHref>
                <MenuItem>Outlet</MenuItem>
              </Link>
                 )}
            </Scroll>
          </MobileNav>
          <Close onClick={() => setOpenNavbar(!openNavbar)} ></Close>
        </>
      )}
    </>
  );
};
