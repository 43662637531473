import { useState, useEffect } from "react";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useRouter } from 'next/router'
import { toast } from "react-toastify";
import cookie from "js-cookie";
import { useMenu } from "../MenuContext";

import { logout } from "../../services/auth";
import { useUser } from "../UserContext";
import { useCart } from "../CartContext";
import { useOutlet } from "../OutletContext";

import { cartApi } from "../../services/api";

import CTA from "../CTA";
import MobileNav from "./MobileNav";
import MobileNavCHK from "./MobileNavCHK";
import SearchForm from "../SearchForm";
import UserInfo from "../UserInfo";
import Container from "../Container";
import ClipLoader from "react-spinners/ClipLoader";
import Icon from "../Icon";

import {
  Header,
  HeaderWrapper,
  SubHeaderWrapper,
  Logo,
  LogoMenu,
  MenuLinks,
  MenuActions,
  Logout,
  CartIcon,
  Counter,
  HideMobile,
  OutletLink,
  Loading,
  LinkMenu
} from "./styles";
import { Plans, PlansMenu } from "../../styles/home";
import MobileNavPN from "./MobileNavPN";
import MenuItem from "../CategoryList/MenuItem";
import SubMenuItem from "../CategoryList/SubMenuItem";

async function handleRemove(event, id, token, setCart) {
  event.preventDefault();

  const newCart = await cartApi.remove(id, { user_token: token });

  setCart(newCart);

  toast.success("Produto removido do Carrinho!");
}

export default ({ headerAbsolute, token, cart_token }) => {
  const router = useRouter()
  const { user, setUser } = useUser();
  const { cart, setCart } = useCart();
  const [cartToken, setCartToken] = useState(cart_token);
  const { outlet, setOutlet } = useOutlet();
  cookie.set('souvenir', 0);

  const totalAmount = cart
    ? cart.reduce((acc, curr) => acc + curr.amount, 0)
    : 0;

  const totalValue = cart
    ? cart.reduce(
      (acc, curr) =>
        acc +
        ((user && user?.profile == "shopkeeper") ?
          (parseFloat(curr.product.price) +
            (user.ex ? 0 : parseFloat(curr.product.price) *
              (parseFloat(curr.product.tax) / 100)))
          :
          parseFloat(curr.product.price)) *
        curr.amount,
      0
    )
    : 0;


  const currency = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  });

  const currency_usd = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "USD"
  });


  function userType() {
    if (!!user && (user.profile == 'shopkeeper' || user.profile == 'corporate')) {
      return true;
    }
    return false;

  }

  const [loading, setLoading] = React.useState(false);

  async function loadScreen(isLoading) {
    setLoading(isLoading);
    setTimeout(() => {
      setLoading(false)
    }, 5000)
  }

  var pathHREF = false;
  var PNHREF = false;

  if (router.pathname.includes("busca")) {
    pathHREF = true;
  }

  if (router.pathname.includes("nossas-marcas")) {
    PNHREF = true;
  }
  const [menuList, setMenuList] = useState([]);
  const { menu } = useMenu();

  useEffect(() => {
    const modifiedMenu = menu.map(category => {
      return { ...category, expanded: false };
    });
    if (!user) {
      createCart();
    }

    setMenuList(modifiedMenu);
  }, [menu]);
  const [expandedCategories, setExpandedCategories] = useState(false);

  async function createCart() {
    const newCart = await cartApi.add({
      user_token: token,
      cart_token: cookie.get("cart_token")
    });

    cookie.set("cart_token", newCart['id'] ?? "", { expires: 30 });

    setCartToken(newCart['id']);
    setCart(newCart['items']);
  }
  return (
    <>
      {userType() ?
        <script
          dangerouslySetInnerHTML={{
            __html: `    var url = 'https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?59910';
                    var s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = url;
                    var options = {
                  "enabled":true,
                  "chatButtonSetting":{
                      "backgroundColor":"#4dc247",
                      "ctaText":"",
                      "borderRadius":"25",
                      "marginLeft":"0",
                      "marginBottom":"50",
                      "marginRight":"50",
                      "position":"right"
                  },
                  "brandSetting":{
                      "brandName":"Goods Br",
                      "brandSubTitle":"",
                      "brandImg":"/logo_goods_fundo_branco.png",
                      "welcomeText":"Oi, tudo bem? <br>Em que posso lhe ajudar?",
                      "messageText":"Olá eu tenho uma duvida sobre a página {{page_link}}",
                      "backgroundColor":"#0a5f54",
                      "ctaText":"Começar conversa",
                      "borderRadius":"25",
                      "autoShow":false,
                      "phoneNumber":"5554996346710"
                  }
                };
                    s.onload = function() {
                        CreateWhatsappChatWidget(options);
                    };
                    var x = document.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);`
          }}
        />
        :
        <script
          dangerouslySetInnerHTML={{
            __html: `    var url = 'https://wati-integration-service.clare.ai/ShopifyWidget/shopifyWidget.js?59910';
                    var s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = url;
                    var options = {
                  "enabled":true,
                  "chatButtonSetting":{
                      "backgroundColor":"#4dc247",
                      "ctaText":"",
                      "borderRadius":"25",
                      "marginLeft":"0",
                      "marginBottom":"50",
                      "marginRight":"50",
                      "position":"right"
                  },
                  "brandSetting":{
                      "brandName":"Goods Br",
                      "brandSubTitle":"",
                      "brandImg":"/logo_goods_fundo_branco.png",
                      "welcomeText":"Oi, tudo bem? <br>Em que posso lhe ajudar?",
                      "messageText":"Olá eu tenho uma duvida sobre a página {{page_link}}",
                      "backgroundColor":"#0a5f54",
                      "ctaText":"Começar conversa",
                      "borderRadius":"25",
                      "autoShow":false,
                      "phoneNumber":"5554996346710"

                  }
                };
                    s.onload = function() {
                        CreateWhatsappChatWidget(options);
                    };
                    var x = document.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);`
          }}
        />
      }

      <script
        dangerouslySetInnerHTML={{
          __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MBLZHRP');;
        `
        }}
      />

      {loading ?
        <Loading>
          <ClipLoader color={'#F37A24'} loading={loading} size={80} />
        </Loading>
        :
        ""
      }


      <Header vip={user?.vip}>
        <Container>
          <meta name="facebook-domain-verification" content="0zrpcb7u275fzd9madhs0511oju9yq" />
          <HeaderWrapper>

            {PNHREF ? <MobileNavPN loadScreen={loadScreen} /> : pathHREF ? <MobileNavCHK loadScreen={loadScreen} /> : <MobileNav loadScreen={loadScreen} />}


            <Link href="/">
              <a>
                {user?.vip ? (
                  <Logo src={user.logo_url} alt={`Logo ${user.name}`} />
                ) : (
                  <Logo src="/logo.svg" alt="Logo Goods Br" />
                )}
              </a>
            </Link>

            <MenuLinks vip={user?.vip}>
              <PlansMenu>
                <nav style={{ height: "100%" }}>
                  <div class="container" style={{ height: "100%" }}>
                    <ul class="menu-main">

                      <li style={{ height: "100%" }}><LinkMenu style={{ cursor: "pointer", height: "100%", display: "flex", cursor: "pointer", height: "100%", alignItems: "center" }}>Categorias</LinkMenu>
                        <div class="menu-sub">

                          {menu[0].map(brandMenu =>
                            brandMenu.map(brand =>
                              <MenuItem brand={brand} />
                            ))}
                        </div>
                      </li>

                    </ul>
                    <ul class="menu-main">
                      <li style={{ height: "100%" }}><LinkMenu style={{ cursor: "pointer", height: "100%", display: "flex", cursor: "pointer", height: "100%", alignItems: "center" }}>Linhas</LinkMenu>
                        <ul class="menu-sub">
                          {
                            menu[1].map(brandMenu =>
                              brandMenu.map(brand =>
                                <SubMenuItem brand={brand} />
                              )
                            )
                          }
                        </ul>
                      </li>
                    </ul>
                  </div>
                </nav>
              </PlansMenu>
            </MenuLinks>
            <MenuActions vip={user?.vip}>
              <HideMobile>
                <SearchForm />
              </HideMobile>

              <HideMobile>
                <UserInfo />
              </HideMobile>

              <HideMobile>
                {user ? (
                  <Logout onClick={() => logout(setUser)}>Sair</Logout>
                ) : (
                  <CTA href="/cadastrar">Cadastre-se</CTA>
                )}
              </HideMobile>

              {user ? (
                <Link href="/carrinho" passHref>
                  <CartIcon title="Carrinho" vip={user?.vip}>
                    <Counter>{totalAmount}</Counter>
                    <Icon size="20">
                      <FontAwesomeIcon icon={faShoppingCart} />
                    </Icon>
                    {cart ? (
                      cart.length ? (
                        <div className="cart-nav">
                          <div className="cart-items">
                            {cart.map(item => (
                              <div key={item.product.sku}>
                                <img
                                  src={item.product.images[0] || "/no-image.jpg"}
                                />
                                <div>
                                  <span style={{ textTransform: "capitalize" }}>{item.product.name}</span>
                                  <span>
                                    {item.amount} x{" "}
                                    {user && user.ex ? currency_usd.format(item.product.price) : currency.format(item.product.price)}
                                    {item.product.tax > 0 && (user?.profile == "shopkeeper" && !user.ex) && (
                                      <> + {item.product.tax}% de IPI</>
                                    )}
                                  </span>
                                </div>
                                <Icon
                                  size="20"
                                  onClick={event =>
                                    handleRemove(
                                      event,
                                      item.product.id,
                                      token,
                                      setCart
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Icon>
                              </div>
                            ))}

                          </div>
                          <div className="cart-nav-total">
                            <span>Total: {user && user.ex ? currency_usd.format(totalValue) : currency.format(totalValue)}</span>
                            <CTA button onClick={() => router.push("/carrinho")}>
                              Finalizar Compra
                            </CTA>
                          </div>
                        </div>
                      ) : null
                    ) : null}
                  </CartIcon>
                </Link>
              ) : null}

            </MenuActions>
          </HeaderWrapper>
        </Container>
      </Header>
    </>
  );
};
