import styled, { css } from "styled-components";

export const Header = styled.header`
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;

  ${props =>
    props.headerAbsolute &&
    css`
      position: absolute;
      left: 0;
      right: 0;
    `}

  ${props =>
    props.vip &&
    css`
      background-color: rgba(0, 0, 0, 0.8);
    `}

    @media (max-width: 1100px) {
      position: fixed;
      z-index: 10;
      width: 100%;
    }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display:flex;
  justify-content: center;
  align-items: center;
  `;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
`;

export const SubHeaderWrapper = styled.div`
display: none;

@media (min-width: 1200px) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}
`;

export const Logo = styled.img`
  max-width: 160px;
`;

export const LinkMenu = styled.a`
  color: #2f2a2a;
  text-transform: uppercase;
  font-weight: bold;
  padding: 44px 20px;
  text-decoration: none;
  position: relative;

  ${props =>
    props.vip &&
    css`
      color: #dbdbdb;
    `}

  &:after {
    content: "";
    background: #222;
    bottom: 0;
    left: 0;
    position: absolute;
    display: block;
    width: 0;
    height: 4px;
    margin-left: 20px;
    transition: width 0.2s ease;

    ${props =>
      props.vip &&
      css`
        background: #dbdbdb;
      `}
  }

  &:hover {
    &:after {
      width: calc(100% - 40px);
    }
  }
`;

export const MenuLinks = styled.nav`
  display: none;

  @media (min-width: 1200px) {
    display: flex;
    align-items: center;
    height: 100%;
  }

  
`;

export const MenuActions = styled.div`
  display: flex;
  align-items: center;

  ${props =>
    props.vip &&
    css`
      color: #dbdbdb;
    `}
`;

export const Logout = styled.span`
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

export const CartIcon = styled.a`
  color: #403e3e;
  position: relative;
  margin-left: 30px;
  margin-right: 0px;

  @media (max-width: 1000px) {
    margin-left: 0px;
    margin-right: 30px;
  }

  ${props =>
    props.vip &&
    css`
      color: #dbdbdb;
    `}

  .cart-nav {
    color: #666;
    display: none;
    position: absolute;
    right: 0;
    align-items: center;
    flex-direction: column;
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.35);
    padding: 20px;
    border-radius: 3px;
    background-color: #fff;
    z-index: 9;

    .cart-nav-total {
      width: 380px;
      background-color: #fff;
      flex-direction: column;
      align-items: center;
      display: flex;
      padding-top: 20px;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px dashed #a7a7a7;
        padding-bottom: 20px;
        margin-bottom: 20px;

        img {
          width: 20%;
        }

        div {
          padding-left: 20px;
          padding-right: 20px;
          flex: 1;

          span {
            display: block;
            font-size: 12px;
          }
        }
      }
    }
    .cart-items {
      overflow-y: scroll;
      max-height: 300px;
      width: 380px;
      align-items: center;
      flex-direction: column;
      z-index: 1;

      > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px dashed #a7a7a7;
        padding-bottom: 20px;
        margin-bottom: 20px;

        img {
          width: 20%;
        }

        div {
          padding-left: 20px;
          padding-right: 20px;
          flex: 1;

          span {
            display: block;
            font-size: 12px;
          }
        }
      }
    }
  }


  &:hover {
    .cart-nav {
      display: flex;

      @media (max-width: 819px) {
        display: none;
      }
    }
  }
`;

export const Counter = styled.span`
  position: absolute;
  top: -18px;
  left: 12px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #e69e00;
  color: #fff;
  border-radius: 50%;
  text-align: center;
`;

export const HideMobile = styled.span`
  @media (max-width: 819px) {
    display: none;
  }
`;

export const OutletLink = styled.button`
  color: #2f2a2a;
  text-transform: uppercase;
  font-weight: bold;
  padding: 44px 20px;
  position: relative;
  border: none;
  transition: 0.2s;
  cursor: pointer;
  background-color: transparent;
  font-family: Poppins, sans-serif;
  font-size: 14px;

  ${props =>
    props.vip &&
    css`
      color: #dbdbdb;
    `}

  ${props =>
    props.outlet &&
    css`
      background-color: lightgray;
      padding: 10px 34px 10px 14px;
      border-radius: 25px;
    `}

  &:after {
    content: "";
    background: #222;
    bottom: 0;
    left: 0;
    position: absolute;
    display: block;
    width: 0;
    height: 4px;
    margin-left: 20px;
    transition: width 0.2s ease;

    ${props =>
      props.vip &&
      css`
        background: #dbdbdb;
      `}

    ${props =>
      props.outlet &&
      css`
        content: "x";
        border-radius: 50%;
        background-color: #424242;
        position: absolute;
        right: 7px;
        left: auto;
        font-size: 10px;
        color: lightgray;
        top: 10px;
        bottom: auto;
        display: flex;
        line-height: 18px;
        align-items: center;
        width: 20px;
        font-weight: bold;
        height: 20px;
        justify-content: center;
        transition: 0.2s;
      `}
  }

  &:hover {
    ${props =>
      props.outlet &&
      css`
        background-color: #c1c0c0;
        transform: scale(1.05);
      `}

    &:after {
      width: calc(100% - 40px);

      ${props =>
        props.outlet &&
        css`
          width: 20px;
        `}
    }
  }
`;
