import styled from "styled-components";

export const FooterInfosWrapper = styled.section`
  background-color: #eee;
  padding: 95px 0;
`;

export const FooterInfos = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 740px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 540px) {
    grid-template-columns: 1fr;
    grid-row-gap: 150px;
  }

  h2 {
    color: #222;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
`;

export const Institutional = styled.p`
  margin-top: 20px;
  line-height: 23px;
`;

export const Copyright = styled.section`
  padding: 20px 0;
  color: #909090;
  font-size: 11px;
  text-transform: uppercase;
  background: #000;
  text-align: center;
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;

  a {
    color: inherit;
    margin-bottom: 10px;
    text-decoration: none;
  }
`;

export const ContactItem = styled.div`
  margin-bottom: 30px;
`;

export const ContactItemTitle = styled.div`
  display: flex;
  color: #222;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 10px;
  align-items: center;

  h3 {
    margin-left: 13px;
  }
`;

export const ContactItemText = styled.p`
  margin-left: 7px;
  padding-left: 20px;
  border-left: 1px solid #d5d5d5;
  line-height: 23px;
`;

export const Social = styled.div`
  display: flex;
`;

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 30px;
  grid-template-rows: repeat(3, 70%);
  justify-items: center;
  align-items: center;

`;

export const SocialIcon = styled.a`
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  margin-right: 8px;
  align-items: center;
  justify-content: center;
  background-color: #bfbfbf;
  transition: all 0.35s linear;

  &:hover {
    background-color: #e69e00;
  }
`;
