import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faEnvelope,
  faPhone,
  faClock,
  faStore
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import {
  FooterInfosWrapper,
  FooterInfos,
  Institutional,
  FooterLinks,
  ContactItem,
  ContactItemText,
  ContactItemTitle,
  Social,
  SocialIcon,
  Copyright,
  Cards,
  Card
} from "./styles";

import Container from "../Container";
import Icon from "../Icon";

export default function Footer() {

  return (
    <footer>
      <FooterInfosWrapper>
        <Container>
          <FooterInfos>
            <div>
              <img src="/logo.svg" alt="Logo Goods Br" />

              <FooterLinks>
                <a
                  href="https://portal.goodsbr.com.br/sobre/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Sobre a Goods Br
                </a>
                <a
                  href="/trabalhe-conosco/"
                  rel="noopener noreferrer"
                  target="_self"
                >
                  Trabalhe Conosco
                </a>
                <a
                  href="/nossa-historia/"
                  rel="noopener noreferrer"
                  target="_self"
                >
                  História da Goods Br
                </a>
                <a
                  href="https://empreendimentos.goodsbr.com.br/"
                  rel="noopener noreferrer"
                  target="_self"
                >
                  Empreendimentos
                </a>
                <a
                  href="https://blog.goodsbr.com.br"
                  rel="noopener noreferrer"
                  target="_self"
                >
                  Blog
                </a>
                <a
                  href="/nossas-marcas/magazine"
                  rel="noopener noreferrer"
                  target="_self"
                >
                  Magazine
                </a>
                <a
                  href="/nossas-marcas/home"
                  rel="noopener noreferrer"
                  target="_self"
                >
                  Goods Br Home
                </a>
                <a href="/termos-condicoes/"
                  target="_self"
                  rel="noopener noreferrer"
                >Termos e Condições</a>

                <div>
                <h2>Siga-nos</h2>
                <Social>
                  <SocialIcon
                    href="https://www.facebook.com/goodsbr/"
                    target="_blank"
                  >
                    <Icon size="14">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </Icon>
                  </SocialIcon>

                  <SocialIcon
                    href="https://www.instagram.com/goodsbr/"
                    target="_blank"
                  >
                    <Icon size="14">
                      <FontAwesomeIcon icon={faInstagram} />
                    </Icon>
                  </SocialIcon>
                </Social>
              </div>
              </FooterLinks>

              <img style={{ marginBottom: "10px" }} src="/icons/seguranca.png" alt="Site Seguro" />

              <ContactItem>
              <ContactItemTitle>FORMAS DE PAGAMENTO: </ContactItemTitle>
              <Cards>
                <img src="/card_brands/amex.png" />
                <img src="/card_brands/aura.png" />
                <img src="/card_brands/diners.png" />
                <img src="/card_brands/discover.png" />
                <img src="/card_brands/elo.png" />
                <img src="/card_brands/hiper.png" />
                <img src="/card_brands/jcb.png" />
                <img src="/card_brands/master.png" />
                <img src="/card_brands/visa.png" />
              </Cards>
              </ContactItem>
            </div>


            <div>
              <h2>Goods Br Distribuidora </h2>

              <ContactItem>
                <ContactItemTitle>
                  <Icon size="14" color="#666">
                    <FontAwesomeIcon icon={faHome} />
                  </Icon>
                  <h3>Endereço:</h3>
                </ContactItemTitle>
                <ContactItemText>
                  Av. Primeiro de Maio, 3400, Bairro Várzea Grande - Gramado, RS
                  CEP: 95.678-314
                </ContactItemText>
              </ContactItem>

              <ContactItem>
                <ContactItemTitle>
                  <Icon size="14" color="#666">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Icon>
                  <h3>Email:</h3>
                </ContactItemTitle>
                <ContactItemText>contato@goodsbr.com.br</ContactItemText>
              </ContactItem>

              <ContactItem>
                <ContactItemTitle>
                  <Icon size="14" color="#666">
                    <FontAwesomeIcon icon={faPhone} />
                  </Icon>
                  <h3>Telefone:</h3>
                </ContactItemTitle>
                <ContactItemText>(54) 3295-5300</ContactItemText>
              </ContactItem>

              <ContactItem>
                <ContactItemTitle>
                  <Icon size="14" color="#666">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </Icon>
                  <h3>Whatsapp:</h3>
                </ContactItemTitle>
                <ContactItemText>(54) 9 9634-6710</ContactItemText>
              </ContactItem>

              <ContactItem>
                <ContactItemTitle>
                  <Icon size="14" color="#666">
                    <FontAwesomeIcon icon={faClock} />
                  </Icon>
                  <h3>Horário de Funcionamento:</h3>
                </ContactItemTitle>
                <ContactItemText>
                  Segunda - Sexta / 08:00 - 12:00, 13:00 - 17:30 (Exceto
                  Feriados)
                </ContactItemText>
              </ContactItem>


            </div>

            <div>

              <h2>Goods Br Home / Natuzzi Editions</h2>
              <ContactItem>
                <ContactItemTitle>
                  <Icon size="14" color="#666">
                    <FontAwesomeIcon icon={faStore} />
                  </Icon>
                  <h3>Show Room das marcas</h3>
                </ContactItemTitle>
                <ContactItemText>
                  <ul>
                    <li>Goods Br</li>
                    <li>Natuzzi Editions</li>
                    <li>Essenza</li>
                    <li>Riva</li>
                    <li>Carolina Haveroth</li>
                    <li>GS Móveis</li>
                    <li>Decoralle Tapetes</li>
                  </ul>
                </ContactItemText>

              </ContactItem>


              <ContactItem>
                <ContactItemTitle>
                  <Icon size="14" color="#666">
                    <FontAwesomeIcon icon={faHome} />
                  </Icon>
                  <h3>Endereço:</h3>
                </ContactItemTitle>
                <ContactItemText>
                  Av. Borges de medeiros, 2381, Bairro Centro - Gramado, RS
                  CEP: 95.670-092
                </ContactItemText>
              </ContactItem>

              <ContactItem>
                <ContactItemTitle>
                  <Icon size="14" color="#666">
                    <FontAwesomeIcon icon={faPhone} />
                  </Icon>
                  <h3>Telefone:</h3>
                </ContactItemTitle>
                <ContactItemText>(54) 3295-5300 </ContactItemText>
              </ContactItem>
              <ContactItem>
                <ContactItemTitle>
                  <Icon size="14" color="#666">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </Icon>
                  <h3>Whatsapp:</h3>
                </ContactItemTitle>
                <ContactItemText>(54) 9 9634-6710</ContactItemText>
              </ContactItem>

              <ContactItem>
                <ContactItemTitle>
                  <Icon size="14" color="#666">
                    <FontAwesomeIcon icon={faClock} />
                  </Icon>
                  <h3>Horário de Funcionamento:</h3>
                </ContactItemTitle>
                <ContactItemText>
                  Segunda - Domingo / 09:30 – 20:00 (sem fechar ao meio dia)
                </ContactItemText>
              </ContactItem>
            </div>

          </FooterInfos>
        </Container>
      </FooterInfosWrapper>

      <Copyright>
        <Container>
          Copyright © 2021. Goods Br. Todos os direitos reservados.
        </Container>
      </Copyright>
    </footer>
  );
}
