import styled from "styled-components";

export const Hamburger = styled.button`
  margin-left: 30px;
  border: none;
  background-color: transparent;
  border-radius: 0;

  @media (min-width: 1200px) {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;
export const Logos = styled.div`
`;

export const LogoMenu = styled.img`
  max-width: 140px;
`;

export const Scroll = styled.ul`
overflow-y: scroll;
height: 500px;
padding-bottom: 5px;
`;

export const Close = styled.button`
  position: absolute;
  right: 0;
  width: 20%;
  height: 700px;
  top: 110px;
  border: none;
  background-color: rgba(0,0,0,0);
  z-index: 1;
  `;
  
  export const MobileNav = styled.nav`
  position: absolute;
  left: 0;
  width: 80%;
  top: 110px;
  background-color: white;
  padding: 10px 20px;
  border-radius: 6px;
  box-shadow: 1px 1px 19px rgba(0, 0, 0, 0.11);
  z-index: 1;

  .parent {
    height:50px;
    padding-top: 10%;
    position: absolute
    font-size: 18px;
    font-weight: 700;
  }
  
`;

export const ContainerRelease = styled.div`
width: 230px;
height: 120px;
padding: 5px;
margin-top: 0px;
position: relative;
right: 10px;
-moz-border-radius: 5px;
-webkit-border-radius: 5px;
background: #FFF;
border: 1px #D3D3D3 solid;
margin-left: 20px;
padding: 15px;
`;

export const ContainerOutlet = styled.div`
width: 230px;
height: 70px;
padding: 5px;
position: relative;
right: 10px;
-moz-border-radius: 5px;
-webkit-border-radius: 5px;
background: #FFF;
border: 1px #D3D3D3 solid;
margin-left: 20px;
margin-bottom: 30px;
padding: 15px;
`;

export const ContainerOrigin = styled.div`
width: 230px;
height: 70px;
padding: 5px;
position: relative;
right: 10px;
-moz-border-radius: 5px;
-webkit-border-radius: 5px;
background: #FFF;
border: 1px #D3D3D3 solid;
margin-left: 20px;
padding: 15px;
`;

export const Container = styled.div`
width: 230px;
// height: 10px;
padding: 5px;
position: relative;
right: 10px;
-moz-border-radius: 5px;
-webkit-border-radius: 5px;
background: #FFF;
border: 1px #D3D3D3 solid;
margin-left: 20px;
padding: 15px;
`;

export const CategoriesHolder = styled.div`
  max-height: 342px;
  overflow-y: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    max-height: 400px;
  }

`;

export const MenuItem = styled.a`
  display: block;
  padding: 10px 0;
  color: #2f2a2a;
  font-weight: bold;
  text-decoration: none;
`;

export const Logout = styled.span`
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;
